import React from 'react'
import './footer.scss'
import logo from "../../Assets/navlogo.svg";
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
// import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
// import { faCoffee } from '@fortawesome/free-solid-svg-icons'
function Footer() {
    return (
        <>
            <section className="footer-main">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-xl-11 col-lg-12 mx-auto d-flex justify-content-between align-items-center flex-wrap">
                        {/* <img src={logo} className="img-fluid" alt="" /> */}
                        <img src="\icons\navlogo.svg" className="img-fluid logofooter" alt="img"/>
                        <ul className="list-inline listLogos mt-sm-0 mt-3 mx-sm-0 mx-auto">
                            <li className="list-inline-item">
                           <a href="https://discord.gg/legionnetwork" rel="noopener noreferrer" target='_blank'><img src="/assets/discord.svg" alt="img" /></a> 
                            </li>
                            <li className="list-inline-item">
                           <a href="https://twitter.com/LegionNetwork__" rel="noopener noreferrer" target='_blank'><img src="\assets\twitter.svg" alt="img" /></a> 
                            </li>
                            <li className="list-inline-item">
                           <a href="https://t.me/legionnetworkcommunity" rel="noopener noreferrer" target='_blank'><img src="\assets\telegram.svg" alt="img" /></a> 
                            </li>
                            <li className="list-inline-item">
                           <a href="https://www.instagram.com/legionnetwork.io" rel="noopener noreferrer" target='_blank'><img src="\assets\instagram.svg" alt="img" /></a> 
                            </li>
                            <li className="list-inline-item">
                           <a href="https://www.reddit.com/r/legionnetwork_io/" rel="noopener noreferrer" target='_blank'><img src="\assets\reddit.svg" alt="img" /></a> 
                            </li>
                            <li className="list-inline-item">
                           <a href="https://medium.com/@legionnetwork" rel="noopener noreferrer" target='_blank'><img src="\assets\mail.svg" alt="img" /></a> 
                            </li>
                        </ul>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Footer