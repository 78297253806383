import React, { useEffect, useCallback, useState } from 'react';
import { Link } from 'react-router-dom'
import Navbar from '../landing/header/Navbar'
import './newportal.scss'
import useWeb3 from "../../hooks/useWeb3";
import { useWeb3React } from '@web3-react/core'
import { toast } from 'react-toastify';
import { setupNetwork } from '../../utils/wallet'
import { getLaunchpadClaimContract } from '../../utils/contractHelpers'
import Environment from '../../utils/Environment';
import { ClaimFuncLaunchpad } from '../../hooks/Claim';
import { CircularProgress } from '@material-ui/core';
import { Backdrop } from '@material-ui/core';

const Newlaunchpad = () => {
    const {account,chainId } = useWeb3React()
    // const account='0xbfB9C4C6582f066638f955b7c28Ec6a49C5b8D34'
    const [display, setDisplay] = useState(null);
    const [claimed, setClaimed] = useState(false);
    const [open, setOpen] = useState(false);
    const [claimedrightnow, setclaimedrightnow] = useState(0);
    const [claimedmonthstimmer, setclaimedmonthstimmer] = useState();
    const web3 = useWeb3();
    console.log("Pending111 abdullah =========", claimed,claimedrightnow);
    const GetBuyers = async () => {
        // console.log("accc", display, typeof display)
        try {
            const contract = getLaunchpadClaimContract(Environment.launchpadclaim, web3);
            let res = await contract.methods.buyers(account).call();
            setClaimed(res)
        } catch (error) {
            console.log("error11", web3)
        }
    };
    // console.log("whiteeeeee111 abdullah ========", typeof claimed.secondVestingClaimed);

    const GetWhitwlist = async () => {
        // console.log("accc", Environment.claim)
        try {
            const contract = getLaunchpadClaimContract(Environment.launchpadclaim, web3);
            let res = await contract.methods.whitelisted(account).call();
            setDisplay(res / 10 ** 18)
        } catch (error) {
            console.log("error11", web3)
        }
    };

    const { claimeva } = ClaimFuncLaunchpad();
    const ClaimEvanglio = useCallback(async () => {
        setOpen(true)
        try {
            const tx = await claimeva();
            if (tx.status) {
                close()
                setOpen(false)
                toast.success('Claim Successfully ', {
                    position: "top-right",
                    autoClose: 5000,
                });
            }
            else {
                console.log('payemnt claim not done')
            }
        } catch (err) {
            setOpen(false)
            console.log("err22", err);
        }
    });

    const close = () => {
        window.$('#claimModal').modal('hide')
    }

    var now = new Date().getFullYear();;
    // var nowStr = now.toDateString();
    var currentMonth = '';
    // const march = (0 / 100) * display;
    // const aprail = (16.667 / 100) * display;
    const may = (16.667 / 100) * display;
    // const may = (16.667 / 100) * display;

    const june = (16.667 / 100) * display;
    const july = (16.667 / 100) * display;
    const august = (16.667 / 100) * display;
    const september = (16.667 / 100) * display;
    const october = (16.667 / 100) * display;
    useEffect(() => {
        if (display !== null) {
            // const march = (0 / 100) * display;
            // const aprail = (10 / 100) * display;
            const may = (16.667 / 100) * display;
            const june = (16.667 / 100) * display;
            const july = (16.667 / 100) * display;
            const august = (16.667 / 100) * display;
            const september = (16.667 / 100) * display;
            const october = (16.667 / 100) * display;

            let ab = {
                firstVestingClaimed: { month: new Date(1653004800 * 1000), value: may },
                secondVestingClaimed: { month: new Date(1655683200 * 1000), value: june },
                thirdVestingClaimed: { month: new Date(1658275200 * 1000), value: july },
                fourthVestingClaimed: { month: new Date(1660953600 * 1000), value: august },
                fifthVestingClaimed: { month: new Date(1663632000 * 1000), value: september },
                sixthVestingClaimed: { month: new Date(1666224000 * 1000), value: october },

            }
            let totalSum = 0;
            for (let x in claimed) {
                if (ab.hasOwnProperty(x)) {
                    if(new Date() >= ab[x].month && !claimed[x]){
                        totalSum += ab[x].value
                        console.log("in x", totalSum)
                    }
                    
                }
            }

            setclaimedrightnow(totalSum);
            // if (now > 2022) {
            //     for(let x=0; x <=7;x++){
            //         if(claimed[x]== false){
            //             console.log("in x",x)
            //         }
           
            // }
            // } else {
            //     if (currentMonth[1] === 'May') {
            //         // if () {
            //         // let sum = may
            //         setclaimedrightnow(may)
            //         // setclaimedmonthstimmer('2022-05-20')
            //         // console.log("month we have May", may)
            //         // }
            //     }
            //     else if (currentMonth[1] === 'Jun') {
            //         // console.log("month we have June")
            //         if (claimed.firstVestingClaimed === false) {
            //             let sum = may + june
            //             setclaimedrightnow(sum)
            //             // setclaimedrightnow(january)
            //             // setclaimedmonthstimmer('2023-01-31')
            //         } else {
            //             setclaimedrightnow(june)
            //         }
            //         // setclaimedrightnow(june)
            //         // setclaimedmonthstimmer('2022-06-31')
            //     }
            //     else if (currentMonth[1] === 'Jul') {
            //         // console.log("month we have July")
            //         if (claimed.firstVestingClaimed === false && claimed.secondVestingClaimed === false) {
            //             let sum = may + june + july
            //             setclaimedrightnow(sum)
            //             // setclaimedmonthstimmer('2022-09-20')
            //         } else if (claimed.secondVestingClaimed === false) {
            //             let sum = june + july
            //             setclaimedrightnow(sum)
            //             // setclaimedmonthstimmer('2022-09-20')
            //         } else {
            //             setclaimedrightnow(july)
            //             // setclaimedmonthstimmer('2023-01-31')
            //         }

            //     }
            //     else if (currentMonth[1] === 'Aug') {
            //         // console.log("month we have July")
            //         if (claimed.firstVestingClaimed === false && claimed.secondVestingClaimed === false && claimed.thirdVestingClaimed === false) {
            //             let sum = may + june + july + august
            //             setclaimedrightnow(sum)
            //             // setclaimedmonthstimmer('2022-09-20')
            //         } else if (claimed.thirdVestingClaimed === false) {
            //             let sum = july + august
            //             setclaimedrightnow(sum)
            //             // setclaimedmonthstimmer('2022-09-20')
            //         } else {
            //             setclaimedrightnow(august)
            //             // setclaimedmonthstimmer('2023-01-31')
            //         }

            //     }
            //     else if (currentMonth[1] === 'Sep') {
            //         // console.log("month we have July")
            //         if (claimed.firstVestingClaimed === false && claimed.secondVestingClaimed === false && claimed.thirdVestingClaimed === false
            //             && claimed.fourthVestingClaimed === false) {
            //             let sum = may + june + july + august + september
            //             setclaimedrightnow(sum)
            //             // setclaimedmonthstimmer('2022-09-20')
            //         } else if (claimed.fourthVestingClaimed === false) {
            //             let sum = august + september
            //             setclaimedrightnow(sum)
            //             // setclaimedmonthstimmer('2022-09-20')
            //         } else {
            //             setclaimedrightnow(september)
            //             // setclaimedmonthstimmer('2023-01-31')
            //         }
            //     } else if (currentMonth[1] === 'Oct') {
            //         if (claimed.firstVestingClaimed === false && claimed.secondVestingClaimed === false && claimed.thirdVestingClaimed === false
            //             && claimed.fourthVestingClaimed === false && claimed.fifthVestingClaimed === false) {
            //             let sum = may + june + july + august + september + october
            //             setclaimedrightnow(sum)
            //             // setclaimedmonthstimmer('2022-09-20')
            //         } else if (claimed.fifthVestingClaimed === false) {
            //             let sum = september + october
            //             setclaimedrightnow(sum)
            //             // setclaimedmonthstimmer('2022-09-20')
            //         } else {
            //             setclaimedrightnow(october)
            //             // setclaimedmonthstimmer('2023-01-31')
            //         }
            //     } else if (claimed.sixthVestingClaimed === false) {
            //         if (claimed.firstVestingClaimed === false && claimed.secondVestingClaimed === false && claimed.thirdVestingClaimed === false
            //             && claimed.fourthVestingClaimed === false && claimed.fifthVestingClaimed === false) {
            //             // console.log("herererer")/
            //             let sum = may + june + july + august + september + october
            //             setclaimedrightnow(sum)
            //             // setclaimedmonthstimmer('2022-09-20')
            //         } else {
            //             setclaimedrightnow(october)
            //             // setclaimedmonthstimmer('2023-01-31')
            //         }
            //     }
            // }

        }
    }, [display,claimed,account])


    const switchNetwork = () => {
        setupNetwork(56)
    }
    useEffect(() => {
        GetBuyers()
        timer()
        // claimedbymonth()
        GetWhitwlist()
    }, [account, chainId])


    const [day, setDay] = useState(0);
    const [hour, setHour] = useState(0);
    const [min, setMin] = useState(0);
    const [sec, setSec] = useState(0);
    var now1 = new Date(claimedmonthstimmer)
    function timer() {
        var time = new Date()
        // var d1 = time.getHours()
        var diff = now1.getTime() - time.getTime()
        console.log("timemmee we have here is", diff)
        if (diff <= 0) {
            return;
        }
        var days = Math.floor(diff / (1000 * 60 * 60 * 24));
        var hours = Math.floor(diff / (1000 * 60 * 60));
        var mins = Math.floor(diff / (1000 * 60));
        var secs = Math.floor(diff / 1000);
        var d = days;
        var h = hours - days * 24;
        var m = mins - hours * 60;
        var s = secs - mins * 60;
        setDay(d);
        setHour(h);
        setMin(m);
        setSec(s)
    }
    return (
        <>
            <Backdrop className="loader" sx={{ color: '#fff' }} open={open}><CircularProgress color="inherit" /></Backdrop>
            <Navbar />
            <section className='portal'>
                <img src='\assets\shade1.png' alt='img' className='topshade' />
                <div className='container-fluid'>
                    <div className='row'>
                        <div className='col-xl-10 col-lg-12 col-md-12 p-0 m-auto'>
                            <div className='mainban'>
                                <div className='heading'>
                                    <h3 className='mainhead'>Ignition Launchpad, CoinxPad,Zeroswap</h3>
                                </div>
                                <div className='col-lg-12 col-md-12 p-0 mb-5'>
                                    <div className='mobileimg'>
                                        <a href='https://register.legionnetwork.io/ogLAQKM' target='_blank' rel="noreferrer">
                                            <img src='\icons\VestingBanner.png' alt='img' className='img-fluid w-100' />
                                        </a>
                                    </div>
                                </div>
                                <div className='row'>
                                    <div className='col-sm-12 p-0'>
                                        <div className='uppertab'>
                                            <div className='innertab'>
                                                <p className='para'>Wallet Address</p>
                                                <h6 className='head'>{account ? account : ''}</h6>
                                            </div>
                                            <div className='innertab'>
                                                <p className='para'>No. of Tokens Bought</p>
                                                <h6 className='head'>{display ? display : 0} LGX</h6>
                                            </div>
                                            <div className='innertab border-none m-0'>
                                                <p className='para'>Claimable Right Now</p>
                                                <h6 className='head'>{claimedrightnow?.toFixed(1)}LGX</h6>
                                            </div>
                                        </div>
                                        <div className='lowertab'>
                                            <h6 className='bluehead'>Schedule</h6>
                                            {chainId === 56 && display !== '0' ?
                                                <>
                                                    <div className='mainlower'>
                                                        <div className='innerlow'>
                                                            <p className='para'>April 2022</p>
                                                            <h5>Already claimed on launchpad</h5>
                                                        </div>
                                                        <div className='innerlow'>
                                                            <p className='para'>May 2022</p>
                                                            <h6 className='head'>{may.toFixed(2)} LGX</h6>
                                                        </div>
                                                        <div className='innerlow'>
                                                            <p className='para'>June 2022</p>
                                                            <h6 className='head'>{june.toFixed(2)} LGX</h6>
                                                        </div>
                                                        <div className='innerlow border-none m-0'>
                                                            <p className='para'>July 2022</p>
                                                            <h6 className='head'>{july.toFixed(2)} LGX</h6>
                                                        </div>
                                                    </div>
                                                    <div className='mainlower'>
                                                        <div className='innerlow'>
                                                            <p className='para'>August 2022</p>
                                                            <h6 className='head'>{august.toFixed(2)} LGX</h6>
                                                        </div>
                                                        <div className='innerlow'>
                                                            <p className='para'>September 2022</p>
                                                            <h6 className='head'>{september.toFixed(2)} LGX</h6>
                                                        </div>
                                                        <div className='innerlow'>
                                                            <p className='para'>October 2022</p>
                                                            <h6 className='head'>{october.toFixed(2)} LGX</h6>
                                                        </div>
                                                    </div>
                                                </>
                                                : display === '0' ? <h2>You have not bought any LGX </h2> : <button className='btn-common' onClick={switchNetwork}>Switch Network</button>}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='btnlast'>
                                {claimedrightnow > 0 ? <button className='claimbtn' data-toggle="modal" data-target="#claimModal">Claim LGX Now</button> : ''}
                            </div>
                        </div>
                    </div>
                </div>
                <img src='\assets\shade2.png' alt='img' className='bottomshade' />
            </section>
            <div class="modal fade claimmodal" id="claimModal" tabindex="-1" role="dialog" aria-labelledby="claimModalLabel" aria-hidden="true">
                <div class="modal-dialog modal-dialog-centered" role="document">
                    <div class="modal-content">
                        <div class="modal-body">
                            <img src='\assets\money-management.svg' alt='img' className='modalimg' />
                            <h6>Are you sure  you want to claim LGX.</h6>
                        </div>
                        <div class="modal-footer">
                            <button type="button" class="modalbtn" data-dismiss="modal">Close</button>
                            <button type="button" onClick={ClaimEvanglio} class="modalbtn">Yes</button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Newlaunchpad