import React, { useEffect, useCallback, useState } from 'react';
import { Link } from 'react-router-dom'
import Navbar from '../landing/header/Navbar'
import './newportal.scss'
import useWeb3 from "../../hooks/useWeb3";
import { useWeb3React } from '@web3-react/core'
import { toast } from 'react-toastify';
import { setupNetwork } from '../../utils/wallet'
import { getPrivateClaimContract } from '../../utils/contractHelpers'
import Environment from '../../utils/Environment';
// import { ClaimFuncPrivate } from '../../../hooks/Claim';
import { ClaimFuncPrivate } from '../../hooks/Claim';
import { CircularProgress } from '@material-ui/core';
import { Backdrop } from '@material-ui/core';
const claimPrivate = Environment.privateclaim;


const Newprivate = () => {
    const { account, chainId } = useWeb3React()
    // const account='0x92bE68C40e202b12B1a4f175b7850ab8BE2911D9'
    const [open, setOpen] = useState(false);
    const [display, setDisplay] = useState();
    const [rend,setRend] = useState(false)
    const [claimedrightnow, setclaimedrightnow] = useState(0);
    const [claimedmonthstimmer, setclaimedmonthstimmer] = useState();
    const [claimed, setClaimed] = useState(false);
    const web3 = useWeb3();
    console.log("accc", claimed, claimedrightnow)
    const GetBuyers = async () => {
        try {
            const contract = getPrivateClaimContract(claimPrivate, web3);
            let res = await contract.methods.buyers(account).call();
            console.log("ressss1111", res)
            setClaimed(res)
        } catch (error) {
        }
    };

    const GetWhitwlist = async () => {
        console.log("accc", claimPrivate)
        try {
            const contract = getPrivateClaimContract(claimPrivate, web3);
            let res = await contract.methods.whitelisted(account).call();
            console.log("resssss::::::::", res)
            setDisplay(res / 10 ** 18)
        } catch (error) {
            console.log("error11", error)
        }
    };
    const close = () => {
        window.$('#claimModal').modal('hide')
    }

    const { claimeva } = ClaimFuncPrivate();
    const ClaimEvanglio = useCallback(async () => {
        try {
            setOpen(true)
            const tx = await claimeva();
            if (tx.status) {
                close()
                setOpen(false)
                toast.success('Claim Successfully ', {
                    position: "top-right",
                    autoClose: 5000,
                });
                checkClaim()

            }
            else {
                console.log('payemnt claim not done')
            }
        } catch (err) {
            setOpen(false)
            close()
            console.log("err22", err);
        }
    });

    const may1 = 10 / 100 * display;
    const aprail = 0 / 100 * display;
    const december = 12.857 / 100 * display;
    const january = 12.857 / 100 * display;
    const february = 12.857 / 100 * display;
    const march1 = 12.857 / 100 * display;
    const april = 12.857 / 100 * display;
    const may = 12.857 / 100 * display;
    const june = 12.857 / 100 * display;

    var now = new Date().getMonth();
    var now1 = new Date().getFullYear();;
    // var nowStr = now.toDateString();
    var currentMonth = ''
    console.log(currentMonth[1]);
    console.log('claim Right now :::::', claimedrightnow, now)

    const checkClaim=()=>{
        if (display !== null) {
            const may1 = 10 / 100 * display;
            const december = 12.857 / 100 * display;
            const january = 12.857 / 100 * display;
            const february = 12.857 / 100 * display;
            const march1 = 12.857 / 100 * display;
            const april = 12.857 / 100 * display;
            const may = 12.857 / 100 * display;
            const june = 12.857 / 100 * display;
            let ab = {
                firstVestingClaimed: { month: new Date(1653004800 * 1000), value: may1 },
                secondVestingClaimed: { month: new Date(1671494400 * 1000), value: december },
                thirdVestingClaimed: { month: new Date(1674172800 * 1000), value: january },
                fourthVestingClaimed: { month: new Date(1676851200 * 1000), value: february },
                fifthVestingClaimed: { month: new Date(1679270400 * 1000), value: march1 },
                sixthVestingClaimed: { month: new Date(1681948800 * 1000), value: april },
                seventhVestingClaimed: { month: new Date(1684540800 * 1000), value: may },
                eighthVestingClaimed: { month: new Date(1687219200 * 1000), value: june },
            }
            let totalSum = 0;
            for (let x in claimed) {
                if (ab.hasOwnProperty(x)) {
                    if(new Date() >= ab[x].month && !claimed[x]){
                        totalSum += ab[x].value
                        console.log("in x", totalSum)
                    }
                    
                }
            }
            setclaimedrightnow(totalSum);
            setRend(!rend)
        }
    }

    useEffect(() => {
        checkClaim()
    }, [display, account, claimed])

    const switchNetwork = () => {
        setupNetwork(56)
    }
    useEffect(() => {
        GetWhitwlist()
        GetBuyers()
    }, [account, chainId])

    return (
        <>
            <Backdrop className="loader" sx={{ color: '#fff' }} open={open}><CircularProgress color="inherit" /></Backdrop>
            <Navbar />
            <section className='portal'>
                <img src='\assets\shade1.png' alt='img' className='topshade' />
                <div className='container-fluid'>
                    <div className='row'>
                        <div className='col-xl-10 col-lg-12 col-md-12 p-0 m-auto'>
                            <div className='mainban'>
                                <div className='heading'>
                                    <h3 className='mainhead'>Private Sale Claim</h3>
                                </div>
                                <div className='col-lg-12 col-md-12 p-0 mb-5'>
                                    <div className='mobileimg'>
                                        <a href='https://register.legionnetwork.io/ogLAQKM' target='_blank' rel="noreferrer">
                                            <img src='\icons\VestingBanner.png' alt='img' className='img-fluid w-100' />
                                        </a>
                                    </div>
                                </div>

                                <div className='row'>
                                    <div className='col-sm-12 p-0'>
                                        <div className='uppertab'>
                                            <div className='innertab'>
                                                <p className='para'>Wallet Address</p>
                                                <h6 className='head'>{account ? account : ''}</h6>
                                            </div>
                                            <div className='innertab'>
                                                <p className='para'>No. of Tokens Bought</p>
                                                <h6 className='head'>{display ? display : 0} LGX</h6>
                                            </div>
                                            <div className='innertab border-none m-0'>
                                                <p className='para'>Claimable Right Now</p>
                                                <h6 className='head'>{claimedrightnow} LGX</h6>
                                            </div>
                                        </div>
                                        <div className='lowertab'>
                                            <h6 className='bluehead'>Schedule</h6>
                                            {chainId === 56 && display != '0' ?
                                                <>
                                                    <div className='mainlower'>
                                                        <div className='innerlow'>
                                                            <p className='para'>April 2022</p>
                                                            <h6 className='head'>{aprail ? aprail : 0} LGX</h6>
                                                        </div>
                                                        <div className='innerlow'>
                                                            <p className='para'>May 2022</p>
                                                            <h6 className='head'>{may1 ? may1 : 0} LGX</h6>
                                                        </div>
                                                        <div className='innerlow'>
                                                            <p className='para'>December 2022</p>
                                                            <h6 className='head'>{december ? december : 0} LGX</h6>
                                                        </div>
                                                        <div className='innerlow border-none m-0'>
                                                            <p className='para'>January 2023</p>
                                                            <h6 className='head'>{january ? january : 0} LGX</h6>
                                                        </div>
                                                        <div className='innerlow'>
                                                            <p className='para'>February 2023</p>
                                                            <h6 className='head'>{february ? february : 0}  LGX</h6>
                                                        </div>
                                                    </div>
                                                    <div className='mainlower'>
                                                        <div className='innerlow'>
                                                            <p className='para'>March 2023</p>
                                                            <h6 className='head'>{march1 ? march1 : 0} LGX</h6>
                                                        </div>
                                                        <div className='innerlow'>
                                                            <p className='para'>April 2023</p>
                                                            <h6 className='head'>{april ? april : 0} LGX</h6>
                                                        </div>
                                                        <div className='innerlow border-none m-0'>
                                                            <p className='para'>May 2023</p>
                                                            <h6 className='head'>{may ? may : 0} LGX</h6>
                                                        </div>
                                                        <div className='innerlow border-none m-0'>
                                                            <p className='para'>June 2023</p>
                                                            <h6 className='head'>{june ? june : 0} LGX</h6>
                                                        </div>
                                                    </div>
                                                </>
                                                : display == '0' ? <h2>You have not bought any LGX </h2> : <button className='btn-common' onClick={switchNetwork}>Switch Network</button>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='btnlast'>
                                {claimedrightnow > 0 ? <button className='claimbtn' data-toggle="modal" data-target="#claimModal">Claim LGX Now</button> : ''}
                            </div>
                        </div>
                    </div>
                </div>
                <img src='\assets\shade2.png' alt='img' className='bottomshade' />
            </section>
            <div class="modal fade claimmodal" id="claimModal" tabindex="-1" role="dialog" aria-labelledby="claimModalLabel" aria-hidden="true">
                <div class="modal-dialog modal-dialog-centered" role="document">
                    <div class="modal-content">
                        <div class="modal-body">
                            <img src='\assets\money-management.svg' alt='img' className='modalimg' />
                            <h6>Are you sure  you want to claim LGX.</h6>
                        </div>
                        <div class="modal-footer">
                            <button type="button" class="modalbtn" data-dismiss="modal">Close</button>
                            <button type="button" class="modalbtn" onClick={ClaimEvanglio} >Yes</button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Newprivate