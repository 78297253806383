import './App.scss';
import Landing from './components/landing/Landing.js';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import useEagerConnect from './hooks/useEagerConnect'
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Footer from './components/footer/Footer';
// import Sale from './components/sale/sale/Sale';
// import Claim from './components/sale/claim/Claim';
// import PublicClaim from './components/sale/claim/PublicClaim'
// import SeedClaim from './components/sale/claim/SeedClaim'
// import launchpadClaim from './components/sale/claim/Launchpad';
import Newpublic from './components/Newportal/Newpublic';
import Newseed from './components/Newportal/Newseed';
import Newprivate from './components/Newportal/Newprivate';
import Newlaunchpad from './components/Newportal/Newlaunchpad';

function App() {
  useEagerConnect()
  return (
    <>
          <Router>
            <ToastContainer style={{ fontSize: 20 }} />
            <Switch>
              <Route exact path='/' component={Landing} />
              {/* <Route exact path='/metabillionaire' component={BuyLgx} /> */}
              {/* <Route exact path='/oglegionary' component={OgLeg} /> */}
              {/* <Route exact path='/guardian' component={GuardianLeg} /> */}
              {/* <Route exact path='/whitelistlegionary' component={WhitelistLgx} /> */}
              {/* <Route exact path='/public' component={PublicLau} /> */}
              {/* <Route exact path='/asianlaunchpad' component={PublicAsia} /> */}
              {/* <Route exact path='/vesting' component={Sale} />  */}
              {/* <Route exact path='/Privateclaim' component={Claim} />     */}
              {/* <Route exact path='/Publicclaim' component={PublicClaim} />   */}
              {/* <Route exact path='/launchpadclaim' component={launchpadClaim} />    */}
              {/* <Route exact path='/Seedclaim' component={SeedClaim} />      */}
              <Route exact path='/Publicclaim' component={Newpublic} />  
              <Route exact path='/Seedclaim' component={Newseed} />  
              <Route exact path='/Privateclaim' component={Newprivate} />   
              <Route exact path='/launchpadclaim' component={Newlaunchpad} />   
            </Switch>
            <Footer/>
          </Router>
    </>
  );
}

export default App;
