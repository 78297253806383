import { useEffect, useState } from 'react'
import BigNumber from 'bignumber.js'
import { useWeb3React } from '@web3-react/core'
import { useCallback } from 'react'
import useWeb3 from './useWeb3'
// import { Evangelism } from '../utils/contractHelpers'
import Environment from '../utils/Environment';
import { getClaimContract, getLaunchpadClaimContract, getSeedClaimContract, getPrivateClaimContract } from '../utils/contractHelpers'

const claimfun = Environment.public;
const claimLaunchpad = Environment.launchpadclaim;
const claimSeed = Environment.seedclaim;
const claimPrivate = Environment.privateclaim;




const ClaimFunc = () => {
  const { account } = useWeb3React();
  // console.log("account we have here is ",account)
  const web3 = useWeb3();
  const contract = getClaimContract(claimfun, web3);
  const Claimlegion = useCallback(async () => {
    // console.log('contract evangelism we have',valueevanga)
    try {
      let gasPrice = await web3.eth.getGasPrice()
      const gas = await contract.methods.claim().estimateGas({ from: account });
      const claimamount = await contract.methods
        .claim()
        .send({ from: account,gasPrice,gas })
        .on("transactionHash", (tx) => {
          return tx.transactionHash;
        })
        .on("error", () => {
          return false;
        });
      //   console.log('response we get here',spinss)
      return claimamount;
    } catch (error) {
      console.log("error:÷:::::", error);
      throw error;
    }
  }, [contract, account]);
  return { claimeva: Claimlegion };
};

const ClaimFuncLaunchpad = () => {
  const { account } = useWeb3React();
  // console.log("account we have here is ",account)
  const web3 = useWeb3();
  const contract = getLaunchpadClaimContract(claimLaunchpad, web3);
  const Claimlegion = useCallback(async () => {
    // console.log('contract evangelism we have',valueevanga)
    try {
      let gasPrice = await web3.eth.getGasPrice()
      const gas = await contract.methods.claim().estimateGas({ from: account });
      const claimamount = await contract.methods
        .claim()
        .send({ from: account, gasPrice,gas })
        .on("transactionHash", (tx) => {
          return tx.transactionHash;
        })
        .on("error", () => {
          return false;
        });
      //   console.log('response we get here',spinss)
      return claimamount;
    } catch (error) {
      console.log("error:÷:::::", error);
      throw error;
    }
  }, [contract, account]);
  return { claimeva: Claimlegion };
};

const ClaimFuncSeed = () => {
  const { account } = useWeb3React();
  // console.log("account we have here is ",account)
  const web3 = useWeb3();
  const contract = getSeedClaimContract(claimSeed, web3);
  const Claimlegion = useCallback(async () => {
    // console.log('contract evangelism we have',valueevanga)
    try {
      let gasPrice = await web3.eth.getGasPrice()
      const gas = await contract.methods.claim().estimateGas({ from: account });
      const claimamount = await contract.methods
        .claim()
        .send({ from: account, gasPrice,gas })
        .on("transactionHash", (tx) => {
          return tx.transactionHash;
        })
        .on("error", () => {
          return false;
        });
      //   console.log('response we get here',spinss)
      return claimamount;
    } catch (error) {
      console.log("error:÷:::::", error);
      throw error;
    }
  }, [contract, account]);
  return { claimeva: Claimlegion };
};

const ClaimFuncPrivate = () => {
  const { account } = useWeb3React();
  // console.log("account we have here is ",account)
  const web3 = useWeb3();
  const contract = getPrivateClaimContract(claimPrivate, web3);
  const Claimlegion = useCallback(async () => {
    // console.log('contract evangelism we have',valueevanga)
    try {
      let gasPrice = await web3.eth.getGasPrice()
      const gas = await contract.methods.claim().estimateGas({ from: account });
      console.log("here",gasPrice);
      const claimamount = await contract.methods
        .claim()
        .send({ from: account , gasPrice,gas  })
        .on("transactionHash", (tx) => {
          return tx.transactionHash;
        })
        .on("error", () => {
          return false;
        });
      //   console.log('response we get here',spinss)
      return claimamount;
    } catch (error) {
      console.log("error:÷:::::", error);
      throw error;
    }
  }, [contract, account]);
  return { claimeva: Claimlegion };
};

export default ClaimFunc;

export { ClaimFuncLaunchpad, ClaimFuncSeed, ClaimFuncPrivate }
