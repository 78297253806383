import web3NoAccount from './web3'
import metabillionaire from './metabillionaire.json'
import payment from './payment.json'
import ustd from './ustd.json'
// import yfEthAbi from './yfethAbi.json';
// import legionnetworkabi from './legionnetworkabi.json'
import busdabi from './busdabi.json'
import legionClaim from './legionClaim.json'
import legionPublic from './legionPublicClaim.json'
import legionLaunchpad from './legionLaunchpadClaim.json'
import seedclaim from './legionSeedClaim.json'
import privateclaim from './legionPrivateClaim.json'


const getContract = (abi, address, web3) => {
    const _web3 = web3 ?? web3NoAccount;
    // console.log('_web3',_web3);
    return new _web3.eth.Contract(abi, address)
}

export const MetaBillionaireContract = (address, web3) => {
    return getContract(metabillionaire, address, web3)
}

export const paymentContract = (address, web3) => {
    return getContract(payment, address, web3)
}

export const ustdContract = (address, web3) => {
    return getContract(ustd, address, web3)
}

export const getBusdContract = (address, web3) => {
    return getContract(busdabi, address, web3)
}

export const getClaimContract = (address, web3) => {
    return getContract(legionClaim, address, web3)
}

export const getSeedClaimContract = (address, web3) => {
    return getContract(seedclaim, address, web3)
}

export const getPublicClaimContract = (address, web3) => {
    return getContract(legionPublic, address, web3)
}

export const getPrivateClaimContract = (address, web3) => {
    return getContract(privateclaim, address, web3)
}

export const getLaunchpadClaimContract = (address, web3) => {
    return getContract(legionLaunchpad, address, web3)
}
