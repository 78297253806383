import React from 'react'
import { Link } from 'react-router-dom'
import Navbar from '../landing/header/Navbar'
import './newportal.scss'


const Newportal = () => {
    return (
        <>
            <Navbar />
            <section className='portal'>
                <img src='\assets\shade1.png' alt='img' className='topshade' />
                <div className='container-fluid'>
                    <div className='row'>
                        <div className='col-xl-10 col-lg-11 col-md-12 p-0 m-auto'>
                            <div className='mainban'>
                                <div className='heading'>
                                    <h3 className='mainhead'>Legion Network <br /> Token Claim Portal</h3>
                                </div>
                                <div className='col-lg-12 col-md-12 p-0 gcctctc'>
                                    <div className='mobileimg'>
                                        <a href='https://register.legionnetwork.io/ogLAQKM' target='_blank' rel="noreferrer">
                                            <img src='\icons\VestingBanner.png' alt='img' className='img-fluid w-100' />
                                        </a>
                                    </div>
                                </div>
                                <div className='innermain'>
                                    <div className='row'>
                                        <div className='col-sm-12 col-md-12 col-lg-6 cardpadd'>
                                            <div className='imgcard'>
                                                <img src='\icons\seed_sale_claim.svg' alt='img' className='moneyimg' />
                                                <p>Seed Sale Claim</p>
                                                <Link to="/Seedclaim">
                                                    <button className='bluebtn'>Go to Claim Page</button>
                                                </Link>
                                            </div>
                                        </div>

                                        <div className='col-sm-12 col-md-12 col-lg-6 cardpadd'>
                                            <div className='imgcard'>
                                                <img src='\icons\private_sale_claim.svg' alt='img' className='moneyimg' />
                                                <p>Private Sale Claim</p>
                                                <Link to="/Privateclaim">
                                                    <button className='bluebtn'>Go to Claim Page</button>
                                                </Link>
                                            </div>
                                        </div>
                                    </div>

                                    <div className='row padd'>
                                        <div className='col-sm-12 col-md-12 col-lg-6 cardpadd'>
                                            <div className='imgcard'>
                                                <img src='\icons\public_sale_claim.svg' alt='img' className='moneyimg' />
                                                <p>Public Sale Claim</p>
                                                <Link to="/Publicclaim">
                                                    <button className='bluebtn'>Go to Claim Page</button>
                                                </Link>
                                            </div>
                                        </div>

                                        <div className='col-sm-12 col-md-12 col-lg-6 cardpadd'>
                                            <div className='imgcard'>
                                                <img src='\icons\ignition_launchpad.svg' alt='img' className='moneyimg' />
                                                <p>Ignition Launchpad, CoinxPad,Zeroswap</p>
                                                <Link to="/launchpadclaim">
                                                    <button className='bluebtn'>Go to Claim Page</button>
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <img src='\assets\shade2.png' alt='img' className='bottomshade' />
            </section>
        </>
    )
}

export default Newportal