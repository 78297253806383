import React, { useEffect, useState }from 'react';
import './navbar.scss';
// import { HashLink } from 'react-router-hash-link';
import logo from "./../../../Assets/navlogo.svg";
import { useWeb3React, UnsupportedChainIdError } from '@web3-react/core'
import { Link } from 'react-router-dom';
import useAuth from '../../../hooks/useAuth';
import { useEagerConnect, useInactiveListener } from '../../../utils/hooks'
import { injected, walletconnect, fortmatic } from '../../../utils/connectors';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { NoEthereumProviderError, UserRejectedRequestError as UserRejectedRequestErrorInjected } from "@web3-react/injected-connector";
import { URI_AVAILABLE, UserRejectedRequestError as UserRejectedRequestErrorWalletConnect } from "@web3-react/walletconnect-connector";


const Navbar = () => {
    const [walletError, setWalletError] = useState('')
    const connectorsByName = {
        Injected: injected,
        WalletConnect: walletconnect,
        Fortmatic: fortmatic,
      };

      function getErrorMessage(error) {
        if (error instanceof NoEthereumProviderError) {
          return "No Ethereum browser extension detected, install MetaMask on desktop or visit from a dApp browser on mobile.";
        } else if (error instanceof UnsupportedChainIdError) {
          setWalletError(toast("You're connected to an unsupported network."))
        } else if (
          error instanceof UserRejectedRequestErrorInjected ||
          error instanceof UserRejectedRequestErrorWalletConnect
        ) {
          setWalletError(toast("Please authorize this website to access your Ethereum account."))
        } else {
          setWalletError(toast("An unknown error occurred. Check the console for more details."))
        }
      }
    //   const { chainID } = useWeb3React()
      const context = useWeb3React();
      const { connector, library, chainId, account, activate, deactivate, active, error } = context;
      const [activatingConnector, setActivatingConnector] = useState();
      React.useEffect(() => {
        if (activatingConnector && activatingConnector === connector) {
          setActivatingConnector(undefined);
        }
      }, [activatingConnector, connector]);
      const triedEager = useEagerConnect();
      useInactiveListener(!triedEager || !!activatingConnector);
      // log the walletconnect URI
      React.useEffect(() => {
        const logURI = uri => {
          console.log("WalletConnect URI", uri);
        };
        walletconnect.on(URI_AVAILABLE, logURI);
        return () => {
          walletconnect.off(URI_AVAILABLE, logURI);
        };
      }, []);

     const connectMetaMask = async () => {
        const currentConnector = connectorsByName['Injected'];
        if (!error) {
          try {
            await activate(currentConnector)
            setActivatingConnector(currentConnector);
            window.$("#exampleModal").modal('hide');

          } catch (ex) {
            return false
          }
        }
        else {
          getErrorMessage(error)
        }
      }

     const walletConnect =  async () => {
        const currentConnector = connectorsByName['WalletConnect'];
        if (!error) {
          try {
            await activate(currentConnector)
            setActivatingConnector(currentConnector);
            window.$("#exampleModal").modal('hide');
          } catch (ex) {
            return false
          }
        }
        else {
          currentConnector.walletConnectProvider = undefined;
          getErrorMessage(error)
        }
      }

    // const connectMetaMask = (e) => {
    //     e.preventDefault()
    //     try {

    //         localStorage.setItem('injected', "injected")
    //         login("injected");
    //         localStorage.setItem('flag', 'true')
    //     }
    //     catch (err) {
    //         return err
    //     }
    // }
    // const connectMetamask = () => {
    //     // e.preventDefault()
    //         try {
    
    //             localStorage.setItem('injected', "injected")
    //             login("injected");
    //             localStorage.setItem('flag', 'true')
    //             window.$("#exampleModal").modal('hide');
    //         }
    //         catch (err) {
    //             return err
    //         }

    // }

    // async function walletConnect() {
    //       try {
    //           localStorage.setItem('injected', "injected")
    //          login("walletconnect")
    //          window.$("#exampleModal").modal('hide');
    //       } catch (err) {
    //           console.log("errr",err)
    //         return false
    
    //       }
        
    //   }
    
    const showModal = () => {
        window.$("#exampleModal").modal('show');
    }
    const disconnect = () => {
        deactivate()
    }

    return (
        <>
        <section className="main-navbar">
            <div className="container-fluid">
                <div className="row">
                    <div className="col-xl-11 col-lg-12 mx-auto">
                        <nav className="navbar navbar-expand-lg p-0">
                            <Link className="navbar-brand" to="/">
                                {/* <img src={logo} className="img-fluid" alt="" /> */}
                                <img src='\icons\navlogo.svg' alt='img' className='img-fluid'/>
                            </Link>
                            <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                                <div class="style-bar"></div>
                                <div class="style-bar"></div>
                                <div class="style-bar"></div>
                            </button>
                            <div className="collapse navbar-collapse" id="navbarSupportedContent">
                                <ul className="navbar-nav mr-auto">
                                </ul>
                                <form className="form-inline my-2 my-lg-0">
                                    {/* <button className='btn-login'>Login</button> */}
                                    {account ?
                                        <button className=" btnnav" type='button' onClick={disconnect}>
                                           Disconnect
                                        </button> :
                                        <button className="btnnav" type='button' onClick={showModal}>
                                             Connect Wallet
                                        </button>
                                    }
                                </form>
                            </div>
                        </nav>
                    </div>
                </div>
            </div>
        </section>
                  <div class="modal fade walletconnect" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                        <div class="modal-dialog modal-dialog-centered">
                            <div class="modal-content">
                                <div class="modal-header">
                                    <h5 class="modal-title text-center w-100" id="exampleModalLabel">Connect Wallet</h5>
                                    <button type="button" class="btn-close" data-dismiss="modal" aria-label="Close"><img src="assets/errorSign.svg" alt="" /></button>
                                </div>
                                <div class="modal-body p-4 text-center">
                                    <button className='w-100 ' type='button' onClick={connectMetaMask}>
                                            <div className="maind">
                                                <img src="\icons\MetaMask.svg" alt="" className='img-fluid' />
                                                <p>Metamask</p>
                                        </div>
                                    </button>
                                    <button className='w-100' type='button' onClick={walletConnect}>
                                            <div className="maind">
                                                <img src="\icons\walletconnect.svg" className='img-fluid' alt="" />
                                                <p>WalletConnect</p>
                                            </div>
                                    </button>

                                </div>
                            </div>
                        </div>
                    </div>
        </>
    )
}

export default Navbar;
