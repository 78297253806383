import React from 'react'
import Newportal from '../Newportal/Newportal'
import Sale from '../sale/sale/Sale'
import Navbar from './header/Navbar'

function Landing() {
    return (
        <>
            {/* <Navbar /> */}
            {/* <Sale /> */}
            <Newportal />
        </>
    )
}

export default Landing