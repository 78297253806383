let Environment = {
    metabillionaire:"0xc6C817cd60E17Fed0AF2A759624e02Dd6c812E64",
    ustd:'0xdAC17F958D2ee523a2206206994597C13D831ec7',
    payment:'0x86B66266115883b62bF87fDE58724Fa402d8AfD0',
    claim:'0x895A2600DF5729dF96802b117Ec810a880BF2023',
    seed:'0x895A2600DF5729dF96802b117Ec810a880BF2023',
    public:'0xC9e3eaeEabd84C8eD2Ec88E2793544b4BC1e2946',
    private:'0x895A2600DF5729dF96802b117Ec810a880BF2023',
    privateclaim:'0x5c97F644653c88BA290c91Bd2b5045f300d6D7DD',
    seedclaim:'0xC570D11AB26d18782B52879a03bF96b39ecDD429',
    launchpadclaim:'0xfB697Ae152611467e897A905d6D83BbC72453104'
  
  };
  export default Environment;